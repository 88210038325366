import {
    createRouter,
    createWebHistory
} from "vue-router"
import LoginPage from '../views/LoginPage.vue';
import Dashboard from '../views/Dashboard.vue';

// Routes
// Each route should map to a component.
const routes = [{
        path: '/',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
    },

]

// Create the router instance and pass the `routes` option
const router = createRouter({
    // Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(process.env.BASE_URL),
    routes, // short for `routes: routes`
})

export default router;