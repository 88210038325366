<template>
  <div class="dashboard-page" :class="{ transition: !navBarOpen }">
    <SidePanel
      class="dashboard-nav-panel"
      :class="{ opened: navBarOpen }"
      :menuItemsAndHomeConfig="dashboardMenu"
      @menuItemSelected="setDashboardData"
    />
    <div class="dashboard-content">
      <ContentArea
        @toggleIconClicked="toggleNavBar"
        :tableSpecifications="dashboardData"
        v-if="!displaySummaryPage"
      />
      <SummaryPage
        v-else
        @toggleIconClicked="toggleNavBar"
        :menuItems="dashboardMenu"
      />
    </div>
  </div>
</template>

<script>
import SidePanel from "./SidePanel.vue";
import ContentArea from "./ContentArea.vue";
import SummaryPage from "./SummaryPage.vue";
import { url } from "../App.vue";

export default {
  props: ["company", "role"],
  data() {
    return {
      navBarOpen: true,
      dashboardMenu: [],
      dashboardData: [],
      displaySummaryPage: false,
      currentPage: "summary",
    };
  },
  methods: {
    toggleNavBar,
    retrieveDashboardSpecifications,
    setDashboardData,
  },
  components: {
    SidePanel,
    ContentArea,
    SummaryPage,
  },
  created() {
    this.retrieveDashboardSpecifications();
  },
};

function setDashboardData(selectedMenuData, isSummaryPage) {
  if (isSummaryPage) {
    this.currentPage = "summary";
    this.displaySummaryPage = true;
    return;
  }
  this.dashboardData = selectedMenuData;
  this.displaySummaryPage = false;
}

async function retrieveDashboardSpecifications() {
  const response = await fetch(`${url}/menu`, {
    credentials: "include",
    method: "GET",
  });
  if (response.ok) {
    this.dashboardMenu = await response.json();
    return;
  }
  this.$router.push({ name: "Login" });
}

function toggleNavBar(event) {
  this.navBarOpen = !this.navBarOpen;
}
</script>

<style>
.dashboard-page {
  color: white;
  display: grid;
  grid-template-columns: 17rem 8fr;
  height: 100vh;
  transition: all ease 0.5s;
  font-size: 1rem;
  overflow: hidden;
}
.dashboard-page.transition {
  grid-template-columns: 0rem 10fr;
}
.dashboard-nav-panel {
  color: white;
  position: relative;
  background-color: #106eec;
  grid-column: 1 / span 1;
  grid-row: 1;
  z-index: 0;
  opacity: 0;
  transition: all ease 1s;
}
.dashboard-nav-panel.opened {
  opacity: 1;
}
.dashboard-content {
  position: relative;
  grid-column: 2 / span 1;
  grid-row: 1;
  z-index: 1;
}
</style>