<template>
  <router-view />
</template>

<script>
import LoginPage from "./views/LoginPage.vue";

// export const url = "https://customerdashboard.rampslogistics.com/";
let backend_url = "http://localhost:3000"
if(process.env.NODE_ENV == "production"){
  backend_url = ""
}
console.log(backend_url)
export const url = backend_url;
export default {
  name: "App",
  components: {
    LoginPage,
  },
};
</script>

<style>
</style>
