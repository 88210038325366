<template>
	<section class="dashboard-content-area">
		<TopMenu :tableSpecifications="tableSpecifications" :selectedCountrySpecification="selectedCountrySpecification"
			:groupedFilters="groupedFilters" :table="table" @toggleIconClicked="toggleSideBar" :dataView="dataView"
			@filtersChanged="filtersChanged = !filtersChanged" />
		<TableSubMenu :tableSpecifications="tableSpecifications"
			:selectedCountrySpecification="selectedCountrySpecification" :groupedFilters="groupedFilters" :table="table"
			@columnsChanged="this.initialiseTable" @countryChanged="this.onTableSpecificationChange"
			@filtersChanged="filtersChanged = !filtersChanged" />
		<section class="data-area" :class="{
			loading: dataView[0] === 'loading',
			'display-chart': dataView[0] === 'chart',
		}">
			<div v-show="dataView[0] === 'loading'" class="loader"></div>
			<div v-show="dataView[0] === 'table'" ref="table" id="table"></div>
			<div class="chart">
				<Charts v-if="dataView[0] === 'chart'" :table="table"
					:selectedCountrySpecification="selectedCountrySpecification" :filtersChanged="filtersChanged"
					:tableSpecifications="tableSpecifications" />
			</div>
		</section>
	</section>
	<CardModal :isActive="activateModal" @closeModal="toggleModal" :record="modalRecord" :groupedFilters="groupedFilters"
		:attachmentDefinition="selectedCountrySpecification.attachmentDefinition" />
	<UploadModal ref="UploadModal" :record="modalRecord" :row="modalRow"> </UploadModal>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { url } from "../App.vue";
import { DateTime } from "luxon";
import CardModal from "../components/CardModal.vue";
import UploadModal from "../components/UploadModal.vue";
import TopMenu from "./TopMenu.vue";
import Charts from "./Charts.vue";
import TableSubMenu from "./TableSubMenu.vue";

import _ from "lodash";

export default {
	props: ["tableSpecifications"],
	data() {
		return {
			table: null,
			tableData: [],
			groupedFilters: [],
			selectedCountrySpecification: {
				tableDefinition: [],
			},
			dataView: ["loading"],
			activateModal: false,
			modalRecord: [],
			modalRow: undefined,
			filtersChanged: false,
			contentLoaded: false,
		};
	},
	methods: {
		toggleSideBar,
		retrieveTableData,
		initialiseTable,		
		retrieveFilters,				
		onTableSpecificationChange,
		viewRecordAttachments,
		toggleModal,
		addTableButtons,
		requestData,
	},
	components: {
		CardModal,
		TopMenu,
		TableSubMenu,
		Charts,
		UploadModal
	},
	watch: {
		// whenever tableSpecifications changes, this function will run
		tableSpecifications: onTableSpecificationChange,
		selectedCountrySpecification: {
			async handler(newCountrySpecification) {		
				this.dataView[0] = "loading";				
				// Set the next page loaded state to not loaded
				this.contentLoaded = false;							
				await this.retrieveTableData(newCountrySpecification);
				this.retrieveFilters(newCountrySpecification);
				this.contentLoaded = true;
			},		
		},
	},
	async beforeMount() {
		// Add luxon date library to the Tabulator form via a property to the global object
		window.DateTime = DateTime;		
		// Ensure that default table view loaded on user login is initialised.
		this.onTableSpecificationChange();
  	},	
};

function onTableSpecificationChange() {
	if (this.tableSpecifications.length === 0) return;
	const originaltableSpecifications = this.tableSpecifications.countries.find(
		(country) => country.active
	);
	// Created a copy of the table specifications so that edits to the object within current page does not affect the server's table definition
	this.selectedCountrySpecification = structuredClone(
		originaltableSpecifications
	);	
}

function retrieveFilters(selectedCountrySpecification) {
	if (
		this.table == null ||
		selectedCountrySpecification.tableDefinition.length == 0
	)
		return;
	// Clear all applied filters to the table
	this.table.clearFilter();		
	const tableFilters = selectedCountrySpecification.initialFilter;	
	// assign filters to table (ungrouped)
	// Add all filters whose applied === true (applied) to the table
	for (const filter of tableFilters) {
		if (filter[0].applied && filter[0].filterType === "or") {
			this.table.addFilter([filter]);
		} else if (filter[0].applied) {
			this.table.addFilter(filter);
		}
	}
	// group in order to display (grouped)
	this.groupedFilters = tableFilters;
}

export function toggleSideBar() {
	this.$emit("toggleIconClicked");
}

async function retrieveTableData(newCountrySpecification) {
	if (
		newCountrySpecification == null ||
		newCountrySpecification.tableDefinition.length == 0
	) {
		return;
	}
	// Append the appropriate table buttons to the table view
	this.addTableButtons(newCountrySpecification);
	// retreive the list of fields to be displayed for the table data from the column specification data
	const filteredRequestedFields = retrieveTableFieldsList(
		newCountrySpecification
	);
	await this.requestData(newCountrySpecification, filteredRequestedFields);
}

export function formatURL(url, dataEndpoint, fields) {
	let formattedUrl;
	if (dataEndpoint.includes("?")) {
		formattedUrl = `${url}${dataEndpoint}&fields=${encodeURI(
			JSON.stringify(fields)
		)}`;
	} else {
		formattedUrl = `${url}${dataEndpoint}?fields=${encodeURI(
			JSON.stringify(fields)
		)}`;
	}
	return formattedUrl;
}

async function requestData(newCountrySpecification, fields) {
	const formattedUrl = formatURL(
		url,
		newCountrySpecification.dataEndpoint,
		fields
	);
	// Send request to the server endpoint
	const response = await fetch(formattedUrl, {
		credentials: "include", // Ensure that the credentials cookie is stored and issued with cross origin requests.
		method: "GET", // POST, PUT, DELETE, etc.
	});
	// Retrieve the response body and assign the response json data to the appropriate component property
	// Then initialise the table with the retrieved data

	if (response.ok) {
		const responseBody = await response.json();
		this.tableData = responseBody;
		this.initialiseTable(newCountrySpecification);
		// Stop loading animation
		this.dataView[0] = "table";
	}
}

function retrieveTableFieldsList(newCountrySpecification) {
	// Store fields to be displayed within table to be sent within the HTTP request
	const requestFields = newCountrySpecification.tableDefinition.map(
		(column) => {
			if ("field" in column && "compute" in column) {
				return !column.compute ? column.field : null;
			}
		}
	);
	// remove null values from requested field list
	return requestFields.filter((field) => {
		if (field) return field;
	});
}


function getUploadButtonFormatter(cell) {
	cell.getElement().classList.add("tabulator-row-handle"); // Adds the default styles to the value of the cell
	let element = `
  <div>
	<button class="button is-danger">
	<span class="icon is-small">
	  <svg class="svg-inline--fa fa-paperclip" aria-hidden="true" focusable="false" viewBox="0 0 512 512"><path class="" fill="currentColor" d="M396.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"></path></svg>    </span>
  </button>
  </div>`
	let data = cell.getRow().getData()
	if (data['has_supplier_invoice'] != 'no') {
		element = `<button class="button is-success">
	<span class="icon is-small">
	  <svg class="svg-inline--fa fa-check" aria-hidden="true" focusable="false" viewBox="0 0 512 512"><path class="" fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>    </span>
  </button>`

	}
	return element;
}


function addTableButtons(newCountrySpecification) {
	// Add attachment button if the table records have attachments
	if (newCountrySpecification.uploadDefinition) {
		newCountrySpecification.tableDefinition.push({
			title: "Supplier Invoice",
			formatter: getUploadButtonFormatter,
			headerSort: false,
			visible: true,
			responsive: 0,
			minWidth: 50,
			cellClick: (event, cell) => {

				let data = cell.getRow().getData()
				if (data['has_supplier_invoice'] == 'no') {
					this.modalRow = cell.getRow();
					this.modalRecord = cell.getRow().getData();
					this.$refs.UploadModal.openModal()
				}
			},
		});
	}



	if (newCountrySpecification.attachments) {
		newCountrySpecification.tableDefinition.push({
			title: "Attachments",
			formatter: getRecordAttachmentsFormatter,
			headerSort: false,
			visible: true,
			responsive: 0,
			minWidth: 50,
			cellClick: this.viewRecordAttachments,
		});
	}



	// Add toggle buttons to table columns
	newCountrySpecification.tableDefinition.push({
		title: "",
		formatter: customResponsiveCollapseFormatter,
		headerSort: false,
		visible: true,
		responsive: 0,
		minWidth: 50,
	});
}

function initialiseTable(newCountrySpecification) {
	//create Tabulator on DOM element with id "example-table"
	this.table = new Tabulator(this.$refs.table, {
		minHeight: "100%", // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
		data: this.tableData, //assign data to table
		layout: "fitDataStretch", //resize column width to ensure that all displayed columns fit within the width of the table and the data is being correctly displayed
		responsiveLayout: "collapse", //hide columns that dont fit on the table
		history: true, //allow undo and redo actions on the table
		pagination: "local", //paginate the data
		paginationSize: 7, //allow 7 rows per page of data
		paginationCounter: "rows", //display count of paginated rows in footer
		rowHeight: 50, //set rows to 40px height
		movableColumns: true, //allow column order to be changed
		responsiveLayoutCollapseStartOpen: false,
		resizableColumnFit: true,
		autoResize: true, // prevent auto resizing of table
		layoutColumnsOnNewData: true,
		placeholder: "No Data Available", //display message to user on empty table	
		paginationSizeSelector: [10, 25, 50, 100, true], //select list with an "all" option at the end of the list		
		columns: this.selectedCountrySpecification.tableDefinition,
		initialSort: newCountrySpecification.initialSort,
		groupBy: newCountrySpecification.groupBy || [],
		groupStartOpen: newCountrySpecification.groupStartOpen || null,
	});
	console.log(newCountrySpecification)
	// Add an cell edited event to the table which will use to trigger the method to update shipment comment
	this.table.on("cellEdited", updateKPIComment);
}

function updateKPIComment(cell) {
	const editedRowData = cell.getRow().getData();
	if ("Shipper" in editedRowData) {
		updateShipmentComment(editedRowData);
	} else if ("invoice_date" in editedRowData) {
		updateInvoiceComment(editedRowData);
	} else if ("shipper" in editedRowData) {
		updateLaserShipmentKpiComment(editedRowData);
	}
}

function updateLaserShipmentKpiComment(editedRowData) {
	fetch(`${url}/comments/Laser/shipment`, {
		method: "PUT",
		credentials: "include",
		headers: {
			"Content-Type": "application/json;charset=utf-8",
		},
		body: JSON.stringify(editedRowData),
	});
}

function updateShipmentComment(editedRowData) {
	fetch(`${url}/comments/IntermediateDB/shipment`, {
		method: "PUT",
		credentials: "include",
		headers: {
			"Content-Type": "application/json;charset=utf-8",
		},
		body: JSON.stringify(editedRowData),
	});
}

function updateInvoiceComment(editedRowData) {
	fetch(`${url}/comments/Laser/invoice`, {
		method: "PUT",
		credentials: "include",
		headers: {
			"Content-Type": "application/json;charset=utf-8",
		},
		body: JSON.stringify(editedRowData),
	});
}

function toggleList(isOpen, toggleButtonContainer, cell) {
	// Retrieve the additional info section for the associated row
	const collapse = cell
		.getRow()
		.getElement()
		.getElementsByClassName("tabulator-responsive-collapse")[0];

	let open = isOpen;
	// If the state is open, ensure to display the button with the class open and display the additional columns for that row
	// else ensure to display the button without the open class and hide the additional columns for that row
	if (open) {
		toggleButtonContainer.classList.add("open");
		if (collapse) {
			collapse.style.display = "";
		}
	} else {
		toggleButtonContainer.classList.remove("open");
		if (collapse) {
			collapse.style.display = "none";
		}
	}
	return open;
}

function customResponsiveCollapseFormatter(cell, formatterParams, onRendered) {
	let open = false; // Initial state of the additional information section
	// Create the element to contain the two buttons which will be swapped according to the state of the additional fields.
	const toggleButtonContainer = document.createElement("div");
	// Specify that the element will contain our toggle buttons by adding the appropriate class used by tabulator
	toggleButtonContainer.classList.add("tabulator-responsive-collapse-toggle");
	// Define the toggle buttons to be contained within the column
	toggleButtonContainer.innerHTML = `
	<button class='tabulator-responsive-collapse-toggle-open'>
	  Expand
	</button>
	<button class='tabulator-responsive-collapse-toggle-close'>
	  Collapse
	</button>`;

	cell.getElement().classList.add("tabulator-row-handle"); // Adds the default styles to the value of the cell

	// If the table property defines the additional columns to be displayed on page load, change the state accordingly
	if (this.table.options.responsiveLayoutCollapseStartOpen) {
		open = true;
	}

	// add event listener to toggle the additional columns display and to change to the appropriate button
	// Update the open state after the method has executed
	toggleButtonContainer.addEventListener("click", function () {
		open = toggleList(!open, toggleButtonContainer, cell);
	});

	// Initialise the table view based on default value
	open = toggleList(open, toggleButtonContainer, cell);

	// Return the customised element containing the toggle buttons to be displayed within the column
	return toggleButtonContainer;
}

function getRecordAttachmentsFormatter(cell) {
	cell.getElement().classList.add("tabulator-row-handle"); // Adds the default styles to the value of the cell
	return `
  <div class="tabulator-responsive-collapse-toggle">
	<button class="attachment-button">
	  View
	</button>
  </div>`;
}

function viewRecordAttachments(event, cell) {
	this.modalRecord = cell.getRow().getData();
	this.toggleModal();
}
function toggleModal() {
	this.activateModal = !this.activateModal;
}
</script>

<style lang="scss">
section.dashboard-content-area {
	display: grid;
	grid-template-rows: 1fr 1fr 10fr;
	height: 100vh;
	overflow: scroll;

	.control {
		background-color: rgba(247, 244, 244, 0.342);
	}

	.data-area {
		background-color: rgba(247, 244, 244, 0.342);
		grid-row: 3;
		padding-top: 1rem;
		padding-inline:3%;
		overflow: scroll;

		&.loading {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		#table {
			color: black;
		}
	}
}

.table-title {
	padding: 0 1rem;
}

.tabulator {
	width: 100%;	
	background-color: rgba(247, 244, 244, 0.342);
	font-size:.8rem;

	.tabulator-header {
		margin-bottom: 1rem;
		border-radius: 10px;
		background-color: #106eec;
		color: white;


		.tabulator-col {
			padding: 1rem 0rem;
			border-left: 1px solid white;

			&:nth-child(3) {
				border-left: none;
			}
		}
	}

	.tabulator-footer {
		border: none;
		padding: 0;
		padding-bottom: 2rem;
		margin-top: 0.5rem;

		.tabulator-footer-contents{
			padding:0;
		}

		.tabulator-page,
		.tabulator-page-size {
			background-color: white;
			border: none;
			box-shadow: 1px 0px 10px 0px rgba(204, 197, 197, 0.323);
			color: grey;

			&.active {
				background-color: #3ddc97;
				color: white;
			}
		}

		.tabulator-page-size {
			height: 2rem;
		}
	}
}

.tabulator-row {
	border: none;

	&.tabulator-row-even,
	&.tabulator-row-odd {
		background-color: white;
		border-radius: 10px;
		margin-bottom: 0.4rem;
	}

	.tabulator-cell {
		border: none;
		text-transform: uppercase;		

		.tabulator-responsive-collapse-toggle {
			width: 100%;
			background: none;

			button {
				background: #3ddc97;
				width: 5rem;
				height: 2.2rem;
				border-radius: 10px;
				border: none;
				color: white;
				cursor: pointer;
				font-weight: bold;

				&:hover {
					border: 1px solid #3ddc97;
					background: white;
					color: #3ddc97;
				}

				&:active {
					border: none;
					background: none;
					color: black;
				}
			}
		}
	}

	.tabulator-responsive-collapse {
		border: none;
		border-top: 1px solid rgba(204, 197, 197, 0.323);
		padding-left: 0.75rem;
		padding-top: 0.75rem;
		margin-top: 0.75rem;
		transition: all 1s ease;
	}
}

.loader {
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #106eec;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite;
	/* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.attachment-button-container {
	width: 100%;
	background: none;

	button {
		background: #3ddc97;
		width: 6rem;
		height: 2.2rem;
		border-radius: 10px;
		border: none;
		color: white;
		cursor: pointer;

		font-weight: bold;

		&:hover {
			border: 1px solid #3ddc97;
			background: white;
			color: #3ddc97;
		}

		&:active {
			border: none;
			background: none;
			color: black;
		}
	}
}

.tabulator-row .tabulator-responsive-collapse table tr td {
	text-transform: uppercase;
	font-size:.8rem;
}

.display-chart {
	padding: 3rem;

	.chart {
		height: 100%;
	}
}
</style>