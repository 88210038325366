<template>
  <Line
    id="my-chart-id"
    :options="chartOptions"
    :data="chartData"
    v-if="existingData"
  />
  <p class="no-data-text" v-else>
    There is no data which satisfies the currently applied filters.
  </p>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import {
  calculateWeekNumber,
  calculateWeeksWithinMonth,
} from "./ShipmentKPIChart.vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: { Line },
  props: [
    "table",
    "selectedCountrySpecification",
    "groupedFilters",
    "filtersChanged",
    "tableSpecifications",
  ],
  methods: {
    initialiseChart,
    generateChart,
    setXAxisLabel,
    generateChartData,
    setChartData,
    initialiseCountObject,
    groupSumByField,
  },
  data() {
    return {
      existingData: true,
      chartData: {
        datasets: [
          {
            type: "line",
            label: "VAT",
            data: [],
            borderColor: "#4cc9f0",
            backgroundColor: "#4cc9f0",
          },
          {
            type: "line",
            label: "Duty",
            data: [],
            borderColor: "#4361ee",
            backgroundColor: "#4361ee",
          },
          {
            type: "line",
            label: "GRA Admin Fee",
            data: [],
            borderColor: "#F58A07",
            backgroundColor: "#F58A07",
          },
          {
            type: "line",
            label: "Lien Charges",
            data: [],
            borderColor: "#7209b7",
            backgroundColor: "#7209b7",
          },
          {
            type: "line",
            label: "Handling",
            data: [],
            borderColor: "#99621E",
            backgroundColor: "#99621E",
          },
          {
            type: "line",
            label: "Storage",
            data: [],
            borderColor: "#FC0C20",
            backgroundColor: "#FC0C20",
          },
          {
            type: "line",
            label: "Demurrage",
            data: [],
            borderColor: "#EEB902",
            backgroundColor: "#EEB902",
          },
          {
            type: "line",
            label: "Destuffing",
            data: [],
            borderColor: "#4CE0B3",
            backgroundColor: "#4CE0B3",
          },
          {
            type: "line",
            label: "Toxic License",
            data: [],
            borderColor: "#136F63",
            backgroundColor: "#136F63",
          },
          {
            type: "line",
            label: "Stamp Duty",
            data: [],
            borderColor: "#ED6B86",
            backgroundColor: "#ED6B86",
          },
          {
            type: "line",
            label: "Transportation",
            data: [],
            borderColor: "#B33C86",
            backgroundColor: "#B33C86",
          },
          {
            type: "line",
            label: "Overtime",
            data: [],
            borderColor: "#5CF64A",
            backgroundColor: "#5CF64A",
          },
        ],
        labels: [],
      },
      chartOptions: {
        plugins: {
          title: {
            display: true,
            text: this.tableSpecifications.title,
            color: "#3ddc97",
            font: {
              size: 20,
            },
          },
          legend: {
            position: "right",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true,
            position: "left",
            title: {
              display: true,
              text: "$ Amount",
              padding: 50,
            },
          },
        },
      },
      months: {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      },
    };
  },
  watch: {
    filtersChanged() {
      this.initialiseChart();
    },
  },
  mounted() {
    this.initialiseChart();
  },
};

function initialiseChart() {
  if (this.table === null) return;
  this.chartData.labels = [];
  // If there is no data which satisfies the currently applied filters, display the appropriate content
  const tableData = this.table.getData("active");
  if (tableData.length === 0) {
    this.existingData = false;
    return;
  }
  this.existingData = true;

  // Sort Drafts in descending order
  tableData.sort(function (draft1, draft2) {
    // negative number means that draft1 is sorted before shipment2
    // positive number means that draft2 is sorted before shipment 1
    return (
      new Date(draft2.arrival_date.replace(/-/g, "/")) -
      new Date(draft1.arrival_date.replace(/-/g, "/"))
    );
  });

  // Create date objects using the latest data and earliest date within the recordset
  const firstDate = new Date(tableData[0].arrival_date.replace(/-/g, "/")); // change yyyy-mm-dd to yyyy/mm/dd
  const lastDate = new Date(
    tableData[tableData.length - 1].arrival_date.replace(/-/g, "/")
  );

  let earliestDate, latestDate;
  if (firstDate < lastDate) {
    earliestDate = firstDate;
    latestDate = lastDate;
  } else {
    earliestDate = lastDate;
    latestDate = firstDate;
  }

  // If the earliest date year and latest date year is not the same then set x axis labels using years.
  // If the years are equal and the months are not then set x axis labels using months.
  // If the years are equal,the months are equal, then set x axis labels using weeks.
  if (earliestDate.getFullYear() !== latestDate.getFullYear()) {
    this.generateChart(earliestDate, latestDate, tableData, "year");
  } else if (earliestDate.getMonth() !== latestDate.getMonth()) {
    this.generateChart(earliestDate, latestDate, tableData, "month");
  } else {
    this.generateChart(earliestDate, latestDate, tableData, "week");
  }
  // Change the object property which is binding to the bar chart prop which will cause the chart to reload with the updated data
  this.chartData = structuredClone(this.chartData);
}

// Set x axis labels
function generateChart(earliestDate, latestDate, tableData, period) {
  const periodMethod = {
    year: "getFullYear",
    month: "getMonth",
    week: "getDay",
    day: "getDay",
  };

  const aggregateData = {};
  this.setXAxisLabel(
    earliestDate,
    latestDate,
    aggregateData,
    period,
    periodMethod[period]
  );

  // Generate count data for the x-axis labels
  this.generateChartData(
    tableData,
    aggregateData,
    period,
    periodMethod[period]
  );

  const draftCountPerAmount = this.groupSumByField(aggregateData);

  //  Assign the appropriate count data to the corresponding x-axis label
  this.setChartData(draftCountPerAmount);
}

function setChartData(aggregateData) {
  for (const [index, sum] of aggregateData.entries()) {
    this.chartData.datasets[index].data = [...sum];
  }
}

function groupSumByField(aggregateData) {
  // Create a new array to store the count data for each draft value across the specified period
  const totalPerFieldType = [];
  for (let i = 0; i < this.chartData.datasets.length; i++) {
    totalPerFieldType.push([]);
  }

  for (const [key, period] of Object.entries(aggregateData)) {
    let index = 0;
    for (const [key, sum] of Object.entries(period)) {
      totalPerFieldType[index].push(sum || 0);
      index++;
    }
  }
  return totalPerFieldType;
}

function generateChartData(
  tableData,
  aggregateData,
  periodType,
  retrievePeriodValue
) {
  tableData.reduce((recordCount, record) => {
    const recordDate = new Date(record.arrival_date.replace(/-/g, "/")); //Replace '-' with '/' eg: 2022-01-01 = 2022/01/01

    const periodCalculation =
      periodType === "week"
        ? () => calculateWeekNumber(recordDate)
        : () => recordDate[retrievePeriodValue]();

    overtime: 0, (recordCount[periodCalculation()].vat += parseInt(record.vat));
    recordCount[periodCalculation()].duty += parseInt(record.duty);
    recordCount[periodCalculation()].graAdminFee += parseInt(
      record.graAdminFee
    );
    recordCount[periodCalculation()].lienCharges += parseInt(
      record.lienCharges
    );
    recordCount[periodCalculation()].handling += parseInt(record.handling);
    recordCount[periodCalculation()].storage += parseInt(record.storage);
    recordCount[periodCalculation()].demurrage += parseInt(record.demurrage);
    recordCount[periodCalculation()].destuffing += parseInt(record.destuffing);
    recordCount[periodCalculation()].toxicLicense += parseInt(
      record.toxicLicense
    );
    recordCount[periodCalculation()].stampDuty += parseInt(record.stampDuty);
    recordCount[periodCalculation()].transportation += parseInt(
      record.transportation
    );
    return recordCount;
  }, aggregateData);
  tableData.reduce((recordCount, record) => {
    const recordDate = new Date(record.arrival_date.replace(/-/g, "/")); //Replace '-' with '/' eg: 2022-01-01 = 2022/01/01

    const periodCalculation =
      periodType === "week"
        ? () => calculateWeekNumber(recordDate)
        : () => recordDate[retrievePeriodValue]();

    recordCount[periodCalculation()].vat += parseInt(record.vat);
    recordCount[periodCalculation()].duty += parseInt(record.duty);
    recordCount[periodCalculation()].graAdminFee += parseInt(
      record.graAdminFee
    );
    recordCount[periodCalculation()].lienCharges += parseInt(
      record.lienCharges
    );
    recordCount[periodCalculation()].handling += parseInt(record.handling);
    recordCount[periodCalculation()].storage += parseInt(record.storage);
    recordCount[periodCalculation()].demurrage += parseInt(record.demurrage);
    recordCount[periodCalculation()].destuffing += parseInt(record.destuffing);
    recordCount[periodCalculation()].toxicLicense += parseInt(
      record.toxicLicense
    );
    recordCount[periodCalculation()].stampDuty += parseInt(record.stampDuty);
    recordCount[periodCalculation()].transportation += parseInt(
      record.transportation
    );
    recordCount[periodCalculation()].overtime += parseInt(record.overtime);
    return recordCount;
  }, aggregateData);
}

function setXAxisLabel(
  periodStart,
  periodEnd,
  aggregateData,
  periodType,
  retrievePeriodValue
) {
  if (periodType === "week") {
    const weeksWithinMonth = calculateWeeksWithinMonth(periodStart, periodEnd);
    this.initialiseCountObject(1, weeksWithinMonth, aggregateData, periodType);
    return;
  }
  if (periodType === "month") {
    this.initialiseCountObject(
      periodStart[retrievePeriodValue](),
      periodEnd[retrievePeriodValue](),
      aggregateData,
      periodType
    );
    return;
  } else {
    this.initialiseCountObject(
      periodStart[retrievePeriodValue](),
      periodEnd[retrievePeriodValue](),
      aggregateData,
      periodType
    );
  }
}

function initialiseCountObject(
  periodStart,
  periodEnd,
  aggregateData,
  periodType
) {
  for (let period = periodStart; period <= periodEnd; period++) {
    aggregateData[period] = {
      vat: 0,
      duty: 0,
      graAdminFee: 0,
      handling: 0,
      lienCharges: 0,
      storage: 0,
      demurrage: 0,
      destuffing: 0,
      toxicLicense: 0,
      stampDuty: 0,
      transportation: 0,
      overtime: 0,
    };
    switch (periodType) {
      case "week":
        this.chartData.labels.push(`Week ${period}`);
        break;
      case "month":
        this.chartData.labels.push(`${this.months[period]}`);
        break;
      case "year":
        this.chartData.labels.push(`${period}`);
        break;
    }
  }
}
</script>

<style lang="scss">
.chart-label {
  color: black;
}
.no-data-text {
  color: black;
}
</style>