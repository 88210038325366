<template>
    <form @submit.prevent="submitData" id="document-upload-form" enctype="multipart/form-data">


        <div class="field">
            <label class="label">Supplier Invoice(s)</label>
            <div class="upload-container has-background-white" @dragover="handleDragOver"
                @drop="handleDrop($event, 'supplierInvoice')">
                <div class="border-container">
                    <p class="has-text-dark has-text-centered">
                        Drag and drop invoice files here, or
                        <a id="file-browser" @click="$refs.invoiceFiles.click()">browse</a>
                        your computer.
                    </p>
                </div>
            </div>
            <div class="control is-hidden">
                <div class="file has-name is-fullwidth">
                    <label class="file-label">
                        <input class="file-input" @change="
                            updateFileLabelsOnUpload($event.target, 'supplierInvoice')
                            " type="file" name="supplierInvoice" multiple ref="invoiceFiles" />
                        <div class="file-cta">
                            <span class="file-label"> Choose an Invoice </span>
                        </div>
                        <div ref="invoiceFileLabel" class="file-name">
                            Commercial Invoice.pdf
                        </div>
                    </label>
                </div>
            </div>
            <div class="is-light attachment-container" v-for="file of invoiceList" :key="file.name">
                <p>{{ file.name }}</p>
                <button class="delete has-background-danger" @click="removeInvoiceFile(file)"></button>
            </div>
        </div>
        <progress class="progress is-small is-info" max="100" v-if="submitting">
            60%
        </progress>
    </form>
</template>
  
  
<script>
import imageUrl from "@/assets/logo.png";
import { url } from "../App.vue";

export default {
    data() {
        return {
            imageUrl,
            invoiceList: [],
            waybillFile: null,
            submitting: false,
        };
    },
    props: ['record', 'row'],
    methods: {
        updateFileLabelsOnUpload,
        resetFileLabel,
        submitData,
        handleDragOver,
        handleDrop,
        removeInvoiceFile,
        removeInvoiceFiles,
        download,
    },
};

function handleDragOver(event) {
    event.preventDefault();
}

function handleDrop(event, fieldName) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    // Add dropped files to the invoiceList array
    if (fieldName === "supplierInvoice") {
        for (let i = 0; i < files.length; i++) {
            this.invoiceList.push(files[i]);
        }
    }
}

function removeInvoiceFile(file) {
    const index = this.invoiceList.indexOf(file);
    if (index !== -1) {
        this.invoiceList.splice(index, 1);
    }
    
}

function removeInvoiceFiles() {
    this.invoiceList.forEach(file=>{
        this.removeInvoiceFile(file)
      })
}

function updateFileLabelsOnUpload(element, fieldName) {
    // const fileInput = document.querySelector("#file-js-example input[type=file]");
    if (fieldName === "supplierInvoice" && element.files.length > 0) {
        const fileName = this.$refs.invoiceFileLabel;
        fileName.textContent = element.files[0].name;
        for (let i = 0; i < element.files.length; i++) {
            this.invoiceList.push(element.files[i]);
        }
    }
}

function resetFileLabel() {
    this.$refs.invoiceFileLabel.innerText = "Commercial Invoice.pdf";
    this.$refs.waybillFileLabel.innerText = "Waybill.pdf";
    this.$refs.reference_number.value = "";
    this.invoiceList = [];
    this.waybillFile = null;
}

function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

async function submitData() {
    const invoiceDataTransfer = new DataTransfer();
    const formData = new FormData();
    this.invoiceList.forEach((file) => {
        formData.append("files", file);
    });

    formData.append("id", this.record.id);

    formData.append("files", invoiceDataTransfer.files);
    this.submitting = true;


    let response = await fetch(
        `${url}/file-uploads/warehouse/supplier-invoices`,
        {
            method: "POST",
            body: formData,
            credentials: "include",

        }
    );

    let data = await response.json()
    if (data.supplier_invoice_ids && data.supplier_invoice_ids.length) {
        this.row.update({ 'has_supplier_invoice': 'yes' })
        this.row.getTable().redraw(true);
    }
    this.submitting = false;
    this.removeInvoiceFiles()
    this.$emit("close");

}

</script>
  
<style lang="scss">
.logo-container {
    background-color: yellow;
    width: 50rem;
    margin: auto;
    margin-top: 0.5rem;
    height: 10rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin-right: 6rem;
    }
}

.form-container {
    display: flex;
    height: 60vh;
    align-items: center;
    padding: 0 1rem;
}

#document-upload-form {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    margin: auto;
    margin-top: 0;
    padding: 2rem;

    .field {
        padding: 0.5rem 0;
        margin: 0;
    }

    .form-buttons {
        margin: auto;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;

        .form-button {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 0.2rem;
            border: none;
        }

        .submit {
            background-color: rgb(119, 230, 180);
        }

        .cancel {
            background-color: rgb(242, 104, 104);
            color: white;
        }
    }

    .file-cta {
        width: 11rem;
    }
}

.file-input {
    border-style: dashed;
    cursor: pointer;
}

.file-card {
    background-color: transparent; //rgba(245, 245, 245, 0.5);
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.file-card-content {
    flex: 1;
    color: white;
}

.drop-zone {
    padding: 1rem;
    border: lightgray dashed;
}

.danger-button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    float: left;
}

.upload-container {
    background-color: rgb(239, 239, 239);
    border-radius: 6px;
}

.border-container {
    border: 5px dashed rgba(198, 198, 198, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 7rem;
    padding: 20px;
}

.border-container p {
    font-weight: 600;
    font-size: 1.1em;
    letter-spacing: -1px;
    opacity: 0.65;
}

#file-browser {
    text-decoration: none;
    color: rgb(22, 42, 255);
    border-bottom: 3px dotted rgba(22, 22, 255, 0.85);
}

#file-browser:hover {
    color: rgb(0, 0, 255);
    border-bottom: 3px dotted rgba(0, 0, 255, 0.85);
}

.icons {
    color: #95afc0;
    opacity: 0.55;
}

.attachment-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    background-color: hsl(0, 0%, 96%);
    color: rgba(0, 0, 0, 0.7);
    margin: 0.25rem 0;
    padding: 0.75rem;
}
</style>