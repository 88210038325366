<template>
  <section class="dashboard-content-area">
    <TopMenuVue
      @toggleIconClicked="this.toggleSideBar"
      isSummaryPage="true"
      @filtersChanged="updateTableFilters"
    />
    <div class="summary-charts" :class="{ loading: loading }">
      <div v-show="loading" class="loader"></div>
      <div
        v-for="[index, table] of tabulatorObjects.entries()"
        :key="index"
        class="chart"
      >
        <Charts
          v-show="!loading"
          :table="table"
          :selectedCountrySpecification="
            this.chartMenuItems[index].countries.find(
              (country) => country.active
            )
          "
          :filtersChanged="filtersChanged"
          :tableSpecifications="this.chartMenuItems[index]"
        />
      </div>
    </div>
  </section>
</template>

<script>
import TableSubMenu from "./TableSubMenu.vue";
import TopMenuVue from "./TopMenu.vue";
import { toggleSideBar, formatURL } from "./ContentArea.vue";
import { url } from "../App.vue";
import Charts from "./Charts.vue";
import { generatePreviousMonthStartEndDates } from "./TopMenu.vue";

export default {
  props: ["menuItems"],
  data() {
    return {
      chartMenuItems: [],
      tabulatorObjects: [],
      tableDatas: [],
      filtersChanged: false,
      loading: true,
    };
  },
  components: {
    TableSubMenu,
    TopMenuVue,
    Charts,
  },
  methods: {
    toggleSideBar,
    createTableObjectUsingMenuItems,
    retreiveChartMenuItems,
    retrieveTableData,
    createTabulatorObjects,
    updateTableFilters,
  },
  mounted() {
    this.retreiveChartMenuItems();
  },
};

function retreiveChartMenuItems() {
  for (const parentItem of this.menuItems) {
    for (const childItem of parentItem.childItems) {
      // Set the index according to the chart definition location within the menu item definition
      if (this.menuItems[1].charts.includes(childItem.title)) {
        this.chartMenuItems.push(childItem);
      }
    }
  }
  this.createTableObjectUsingMenuItems();
}

async function createTableObjectUsingMenuItems() {
  for (const menuItem of this.chartMenuItems) {
    const selectedCountrySpecification = menuItem.countries.find(
      (country) => country.active
    );
    let tableFields = selectedCountrySpecification.tableDefinition.map(
      (column) => (!column.compute ? column.field : null)
    );
    tableFields = tableFields.filter((field) => {
      if (field) return field;
    });
    await this.retrieveTableData(
      selectedCountrySpecification.dataEndpoint,
      tableFields
    );
  }
  this.createTabulatorObjects();
}

async function retrieveTableData(tableEndpoint, tableFields) {
  // Send request to the server endpoint
  const formattedUrl = formatURL(url, tableEndpoint, tableFields);
  const response = await fetch(formattedUrl, {
    credentials: "include", // Ensure that the credentials cookie is stored and issued with cross origin requests.
    method: "GET", // POST, PUT, DELETE, etc.
  });
  // Retrieve the response body and assign the response json data to the appropriate component property
  // Then initialise the table with the retrieved data
  const responseBody = await response.json();

  if (response.ok) {
    this.tableDatas.push(responseBody);
  }
}

function createTabulatorObjects() {
  for (const [index, tableData] of this.tableDatas.entries()) {
    const table = new Table(tableData);
    this.tabulatorObjects.push(table);
    this.loading = false;
  }
}

function updateTableFilters(newFilter) {
  for (const table of this.tabulatorObjects) {
    table.setFilter(newFilter);
  }
  this.filtersChanged = !this.filtersChanged;
}

class Table {
  constructor(data, filter = []) {
    this.data = data;
    this.generateDefaultFilter();
  }
  generateDefaultFilter() {
    const [startDateOfPreviousMonth, endDateOfPreviousMonth] =
      generatePreviousMonthStartEndDates();

    this.filter = [
      {
        value: `${startDateOfPreviousMonth.toISOString().split("T")[0]}`,
      },
      {
        value: `${endDateOfPreviousMonth.toISOString().split("T")[0]}`,
      },
    ];
  }
  getData(type) {
    if (
      type === "active" &&
      this.filter.length !== 0 &&
      this.data.length !== 0
    ) {
      if ("ArrivalDate" in this.data[0] || "arrival_date" in this.data[0]) {
        return this.data.filter((shipment) => {
          let arrival_date = shipment.ArrivalDate || shipment.arrival_date;
          const startDate = new Date(this.filter[0].value.replace(/-/g, "/"));
          const endDate = new Date(this.filter[1].value.replace(/-/g, "/"));
          arrival_date = new Date(arrival_date.replace(/-/g, "/"));
          return arrival_date >= startDate && arrival_date <= endDate;
        });
      } else if ("date_ramps" in this.data[0]) {
        return this.data.filter(
          (draft) =>
            new Date(draft.date_ramps.replace(/-/g, "/")) >=
              new Date(this.filter[0].value.replace(/-/g, "/")) &&
            new Date(draft.date_ramps.replace(/-/g, "/")) <=
              new Date(this.filter[1].value.replace(/-/g, "/"))
        );
      }
      return this.data;
    }
    return this.data;
  }
  setFilter(filter) {
    this.filter = filter;
  }
}
</script>

<style lang="scss">
.summary-charts {
  display: grid;
  // grid-template: 50vh 50vh / 1fr 1fr;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  grid-auto-rows: 30rem;
  grid-gap: 1rem;
  padding: 1rem;
  justify-content: center;

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }
}
.chart {
  box-shadow: 0px 0px 5px 0px #3ddc976e;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>