import {
    createApp
} from 'vue'
import App from './App.vue'
import './assets/styles.scss';
// When importing a folder the file labelled index is automatically retrieved
import router from './router';

/* import the fontawesome core */
import {
    library
} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
    faLock,
    faUser,
    faCheck,
    faExclamationTriangle,
    faChartLine,
    faAngleDown,
    faPaperclip,
    faRightFromBracket,
    faSquareCheck,
    faFileInvoiceDollar,
    faSpinner,
    faTruckRampBox,
    faCircleCheck,
    faCircle as faSolidCircle,
    faBars,
    faChartArea,
    faPlaneDeparture,
    faClipboardCheck,
    faBiohazard,
    faShoppingBag,
    faWarehouse,
    faList,
    faTruck,
    faShip,

} from '@fortawesome/free-solid-svg-icons'

import {
    faCircleXmark,
    faCircle,
} from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(
    faLock,
    faUser,
    faCheck,
    faExclamationTriangle,
    faChartLine,
    faAngleDown,
    faCircle,
    faSolidCircle,
    faTruckRampBox,
    faCircleCheck,
    faSpinner,
    faFileInvoiceDollar,
    faSquareCheck,
    faBars,
    faRightFromBracket,
    faCircleXmark,
    faPaperclip,
    faChartArea,
    faPlaneDeparture,
    faClipboardCheck,
    faBiohazard,
    faShoppingBag,
    faWarehouse,
    faList,
    faTruck, 
    faShip,

)

// Instantiate App
const app = createApp(App);

// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)

// Registration of a global component
app.component('font-awesome-icon', FontAwesomeIcon)

// Mount root component to DOM
app.mount('#app')