<template>
  <div class="modal" :class="{ 'is-active': isActive }">
    <div class="modal-background" @click="this.closeModal"></div>
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">Attachments</p>
        <button class="delete" aria-label="close" @click="this.closeModal"></button>
      </header>
      <section class="modal-card-body" :class="{ 'center-loader': loading }">
        <div v-show="loading" class="loader"></div>
        <div class="attachment-container" v-for="attachment of attachments" :key="attachment.name"
          v-show="!loading && attachments.length != 0">
          <div class="attachment-label">{{ attachment.label }}</div>
          <button @click="downloadAttachment(attachment)">Download</button>
        </div>
        <div class="attachment-container" v-show="!loading && attachments.length === 0">
          No Attachments
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { url } from "../App.vue";

export default {
  props: ["isActive", "record", "attachmentDefinition"],
  data() {
    return {
      attachments: [],
      loading: true,
    };
  },
  methods: {
    closeModal,
    retrieveAttachments,
    displayPopulatedAttachments,
    downloadAttachment,
  },
  watch: {
    record() {
      this.loading = true;
      this.retrieveAttachments();
    },
  },
};

async function retrieveAttachments() {
  // The replica db stores shipment reference number using the name field
  // Shipments.brokerage within LASER stores the reference number within referenceNumber
  // Use whichever field exist.
  let referenceNumber;
  let endpoint;
  if (this.attachmentDefinition) {
    endpoint = this.attachmentDefinition.endpoint
    referenceNumber = this.record[this.attachmentDefinition.idField]
    let cargo_image_response = await fetch(
    `${url}/attachments/warehouse/update-cargo-images/${this.record.id}`,
    {
      credentials: "include",
      method: "GET",
    }
  );
  }
  else if ("Name" in this.record || "referenceNumber" in this.record) {
    referenceNumber = this.record.Name || this.record.referenceNumber;
    endpoint = "brokerage/shipments/";
  } else if ("rll_number" in this.record) {
    referenceNumber = this.record.rll_number;
    endpoint = "freight/shipments/";
  } else if ("name" in this.record) {
    referenceNumber = this.record.name;
    endpoint = "invoices/";
  }
  const response = await fetch(
    `${url}/attachments/${endpoint}${referenceNumber.replace(/\//g, "-")}`,
    {
      credentials: "include",
      method: "GET",
    }
  );
  const jsonResponse = await response.json();
  if (response.ok) {
    this.displayPopulatedAttachments(jsonResponse);
  }
}

function displayPopulatedAttachments(listOfAttachments) {
  this.attachments = listOfAttachments.filter(
    (attachment) => attachment.ids.length != 0
  );
  this.loading = false;
}

async function downloadAttachment(attachment) {
  const response = await fetch(
    `${url}/attachments?attachment=${JSON.stringify(attachment)}`,
    {
      credentials: "include",
      method: "GET",
    }
  );
  // Create a link which points to the url of the object and invoke the click event on the link to download the object
  const file = await response.blob();
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(file);
  let referenceNumber =
    this.record.Name ||
    this.record.referenceNumber ||
    this.record.rll_number ||
    this.record.name;
  if (this.attachmentDefinition && !referenceNumber) {
    referenceNumber = this.record[this.attachmentDefinition.idField]
  }
  link.download = `${referenceNumber} ${attachment.label}.zip`;
  link.click();
}

function closeModal() {
  this.$emit("closeModal");
}
</script>

<style lang="scss">
.attachment-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  justify-content: space-between;
  padding: 0.4rem 0;
  color: black;

  button {
    background: #3ddc97;
    width: 6rem;
    height: 2.2rem;
    border-radius: 10px;
    border: none;
    color: white;
    cursor: pointer;

    font-weight: bold;

    &:hover {
      border: 1px solid #3ddc97;
      background: white;
      color: #3ddc97;
    }

    &:active {
      border: none;
      background: none;
      color: black;
    }
  }
}

.center-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>