<template>
  <Modal :title="modalTitle" :is-active="isActive" @close="closeModal">
    <template #default>
      <DocumentUploadForm ref="DocumentUploadForm" :record="record" :row="row" @close="closeModal"/>
    </template>
    <template #footer>
      <button class="button" @click="performAction">Upload</button>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal.vue';
import DocumentUploadForm from './DocumentUploadForm.vue';
import { url } from "../App.vue";

export default {
  name: 'LinkModal',
  components: {
    Modal,
    DocumentUploadForm
  },
  props: ['record', 'row'],
  data() {
    return {
      modalTitle: 'Upload',
      isActive: false,
    }
  },
  async beforeMount() {
    // Ensure that the user's changes persist on change to another component/page such as the summary page
  },
  methods: {
    async openModal() {

      this.isActive = true;

    },
    closeModal() {
      this.isActive = false;
      this.$refs.DocumentUploadForm.removeInvoiceFiles()
    },
    async performAction() {
      this.$refs.DocumentUploadForm.submitData()

    },
    emitConfirmation() {
      this.$emit("close");
      this.closeModal();

    }
  },
}
</script>

<style scoped>
/* Add your Bulma styling classes here */
</style>
