<template>
  <Line
    id="my-chart-id"
    :options="chartOptions"
    :data="chartData"
    v-if="existingData"
  />
  <p class="no-data-text" v-else>
    There is no data which satisfies the currently applied filters.
  </p>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import {
  calculateWeekNumber,
  calculateWeeksWithinMonth,
} from "./ShipmentKPIChart.vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: { Line },
  props: [
    "table",
    "selectedCountrySpecification",
    "groupedFilters",
    "filtersChanged",
    "tableSpecifications",
  ],
  methods: {
    initialiseChart,
    generateChart,
    setXAxisLabel,
    generateChartData,
    setChartData,
    initialiseCountObject,
    groupDraftCountByDraftValue,
  },
  data() {
    return {
      existingData: true,
      chartData: {
        datasets: [
          {
            type: "line",
            label: "$2000",
            data: [],
            borderColor: "#4cc9f0",
            backgroundColor: "#4cc9f0",
          },
          {
            type: "line",
            label: "$6000",
            data: [],
            borderColor: "#4361ee",
            backgroundColor: "#4361ee",
          },
          {
            type: "line",
            label: "$10000",
            data: [],
            borderColor: "#F58A07",
            backgroundColor: "#F58A07",
          },
          {
            type: "line",
            label: "$15000",
            data: [],
            borderColor: "#7209b7",
            backgroundColor: "#7209b7",
          },
          {
            type: "line",
            label: "$20000",
            data: [],
            borderColor: "#f72585",
            backgroundColor: "#f72585",
          },
        ],
        labels: [],
      },
      chartOptions: {
        plugins: {
          title: {
            display: true,
            text: this.tableSpecifications.title,
            color: "#3ddc97",
            font: {
              size: 20,
            },
          },
          legend: {
            position: "right",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true,
            position: "left",
            title: {
              display: true,
              text: "Number of Drafts",
              padding: 50,
            },
          },
        },
      },
      months: {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      },
    };
  },
  watch: {
    filtersChanged() {
      this.initialiseChart();
    },
  },
  mounted() {
    this.initialiseChart();
  },
};

function initialiseChart() {
  if (this.table === null) return;
  this.chartData.labels = [];
  // If there is no data which satisfies the currently applied filters, display the appropriate content
  const tableData = this.table.getData("active");
  if (tableData.length === 0) {
    this.existingData = false;
    return;
  }
  this.existingData = true;

  // Ensure that the properties that will be used for this chart exist before begining computation
  if ("value_gyd" in tableData[0] === false) return;

  // Sort Drafts in descending order
  tableData.sort(function (draft1, draft2) {
    // negative number means that draft1 is sorted before shipment2
    // positive number means that draft2 is sorted before shipment 1
    return (
      new Date(draft2.date_ramps.replace(/-/g, "/")) -
      new Date(draft1.date_ramps.replace(/-/g, "/"))
    );
  });

  // Create date objects using the latest data and earliest date within the recordset
  const firstDate = new Date(tableData[0].date_ramps.replace(/-/g, "/")); // change yyyy-mm-dd to yyyy/mm/dd
  const lastDate = new Date(
    tableData[tableData.length - 1].date_ramps.replace(/-/g, "/")
  );

  let earliestDate, latestDate;
  if (firstDate < lastDate) {
    earliestDate = firstDate;
    latestDate = lastDate;
  } else {
    earliestDate = lastDate;
    latestDate = firstDate;
  }

  // If the earliest date year and latest date year is not the same then set x axis labels using years.
  // If the years are equal and the months are not then set x axis labels using months.
  // If the years are equal,the months are equal, then set x axis labels using weeks.
  if (earliestDate.getFullYear() !== latestDate.getFullYear()) {
    this.generateChart(earliestDate, latestDate, tableData, "year");
  } else if (earliestDate.getMonth() !== latestDate.getMonth()) {
    this.generateChart(earliestDate, latestDate, tableData, "month");
  } else {
    this.generateChart(earliestDate, latestDate, tableData, "week");
  }
  // Change the object property which is binding to the bar chart prop which will cause the chart to reload with the updated data
  this.chartData = structuredClone(this.chartData);
}

// Set x axis labels
function generateChart(earliestDate, latestDate, tableData, period) {
  const periodMethod = {
    year: "getFullYear",
    month: "getMonth",
    week: "getDay",
    day: "getDay",
  };

  const aggregateData = {};
  this.setXAxisLabel(
    earliestDate,
    latestDate,
    aggregateData,
    period,
    periodMethod[period]
  );

  // Generate count data for the x-axis labels
  this.generateChartData(
    tableData,
    aggregateData,
    period,
    periodMethod[period]
  );

  const draftCountPerAmount = this.groupDraftCountByDraftValue(aggregateData);

  //  Assign the appropriate count data to the corresponding x-axis label
  this.setChartData(draftCountPerAmount);
}

function setChartData(aggregateData) {
  for (const [index, count] of aggregateData.entries()) {
    this.chartData.datasets[index].data = [...count];
  }
}

function groupDraftCountByDraftValue(aggregateData) {
  // Create a new array to store the count data for each draft value across the specified period
  const draftCountPerAmount = [];
  for (let i = 0; i < this.chartData.datasets.length; i++) {
    draftCountPerAmount.push([]);
  }

  for (const [key, period] of Object.entries(aggregateData)) {
    let index = 0;
    for (const [key, count] of Object.entries(period)) {
      draftCountPerAmount[index].push(count || 0);
      index++;
    }
  }
  return draftCountPerAmount;
}

function generateChartData(
  tableData,
  aggregateData,
  periodType,
  retrievePeriodValue
) {
  tableData.reduce((recordCount, record) => {
    const recordDate = new Date(record.date_ramps.replace(/-/g, "/")); //Replace '-' with '/' eg: 2022-01-01 = 2022/01/01

    const periodCalculation =
      periodType === "week"
        ? () => calculateWeekNumber(recordDate)
        : () => recordDate[retrievePeriodValue]();
    recordCount[periodCalculation()][record.value_gyd] += 1;
    return recordCount;
  }, aggregateData);
}

function setXAxisLabel(
  periodStart,
  periodEnd,
  aggregateData,
  periodType,
  retrievePeriodValue
) {
  if (periodType === "week") {
    const weeksWithinMonth = calculateWeeksWithinMonth(periodStart, periodEnd);
    this.initialiseCountObject(1, weeksWithinMonth, aggregateData, periodType);
    return;
  }
  if (periodType === "month") {
    this.initialiseCountObject(
      periodStart[retrievePeriodValue](),
      periodEnd[retrievePeriodValue](),
      aggregateData,
      periodType
    );
    return;
  } else {
    this.initialiseCountObject(
      periodStart[retrievePeriodValue](),
      periodEnd[retrievePeriodValue](),
      aggregateData,
      periodType
    );
  }
}

function initialiseCountObject(
  periodStart,
  periodEnd,
  aggregateData,
  periodType
) {
  for (let period = periodStart; period <= periodEnd; period++) {
    aggregateData[period] = {
      2000: 0,
      6000: 0,
      10000: 0,
      15000: 0,
      20000: 0,
    };
    switch (periodType) {
      case "week":
        this.chartData.labels.push(`Week ${period}`);
        break;
      case "month":
        this.chartData.labels.push(`${this.months[period]}`);
        break;
      case "year":
        this.chartData.labels.push(`${period}`);
        break;
    }
  }
}
</script>

<style lang="scss">
.chart-label {
  color: black;
}
.no-data-text {
  color: black;
}
</style>