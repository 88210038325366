<template>
  <ShipmentKPIChartVue
    :table="table"
    :selectedCountrySpecification="selectedCountrySpecification"
    :filtersChanged="filtersChanged"
    :tableSpecifications="tableSpecifications"
    v-if="selectedCountrySpecification.chart.type === 'shipmentKpiChart'"
  />
  <DraftKPIChartVue
    :table="table"
    :selectedCountrySpecification="selectedCountrySpecification"
    :filtersChanged="filtersChanged"
    :tableSpecifications="tableSpecifications"
    v-else-if="selectedCountrySpecification.chart.type === 'draftKpiChart'"
  />
  <BrokerageShipmentCostChart
    :table="table"
    :selectedCountrySpecification="selectedCountrySpecification"
    :filtersChanged="filtersChanged"
    :tableSpecifications="tableSpecifications"
    v-else-if="
      selectedCountrySpecification.chart.type === 'brokerageShipmentCostChart'
    "
  />
</template>

<script>
import DraftKPIChartVue from "./DraftKPIChart.vue";
import ShipmentKPIChartVue from "./ShipmentKPIChart.vue";
import BrokerageShipmentCostChart from "./BrokerageShipmentCostChart.vue";

export default {
  props: [
    "table",
    "selectedCountrySpecification",
    "groupedFilters",
    "filtersChanged",
    "tableSpecifications",
  ],
  components: {
    ShipmentKPIChartVue,
    DraftKPIChartVue,
    BrokerageShipmentCostChart,
  },
};
</script>

<style>
</style>