<template>
  <Bar
    id="my-chart-id"
    :options="chartOptions"
    :data="chartData"
    v-if="existingData"
  />
  <p class="no-data-text" v-else>
    There is no data which satisfies the currently applied filters.
  </p>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  SubTitle,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  SubTitle
);

export default {
  components: { Bar },
  props: [
    "table",
    "selectedCountrySpecification",
    "groupedFilters",
    "filtersChanged",
    "tableSpecifications",
  ],
  methods: {
    initialiseChart,
    generateChart,
    setXAxisLabel,
    generateChartData,
    setChartData,
    initialiseCountObject,
  },
  data() {
    return {
      existingData: true,
      chartData: {
        datasets: [
          {
            type: "bar",
            label: "Inside KPI",
            data: [],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgb(58, 134, 255, .6)",
            order: 1,
            yAxisID: "y",
          },
          {
            type: "bar",
            label: "Outside KPI",
            data: [],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgb(255, 0, 110, .6)",
            order: 1,
            yAxisID: "y",
          },
          {
            type: "line",
            label: "KPI Percentage",
            data: [],
            borderColor: "rgb(8, 252, 130)",
            backgroundColor: "rgb(8, 252, 130)",
            order: 0,
            yAxisID: "y1",
          },
          {
            type: "line",
            label: "KPI Target",
            data: [],
            borderColor: "#F5C44A",
            backgroundColor: "#F5C44A",
            order: 0,
            yAxisID: "y1",
          },
        ],
        labels: [],
      },
      chartOptions: {
        plugins: {
          title: {
            display: true,
            text: this.tableSpecifications.title,
            color: "#3ddc97",
            font: {
              size: 20,
            },
          },
          legend: {
            position: "top",
          },
          subtitle: {
            display: true,
            text: `Air KPI: ${this.selectedCountrySpecification.chart.airKPI} Days, Ocean KPI: ${this.selectedCountrySpecification.chart.oceanKPI} Days`,
            padding: {
              top: 5,
              bottom: 5,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            type: "linear",
            display: true,
            position: "left",
            title: {
              display: true,
              text: "Number Of Shipments",
              padding: 50,
            },
          },
          y1: {
            type: "linear",
            display: true,
            position: "right",
            max: 100,
            title: {
              display: true,
              text: "KPI Percentage %",
              padding: 50,
            },
            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            ticks: {
              // Include a percentage sign in the ticks
              callback: function (value, index, ticks) {
                return value + "%";
              },
            },
          },
        },
      },
      months: {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      },
    };
  },
  watch: {
    filtersChanged() {
      this.initialiseChart();
    },
  },
  mounted() {
    this.initialiseChart();
  },
};

function initialiseChart() {
  if (this.table === null) return;
  this.chartData.labels = [];
  // If there is no data which satisfies the currently applied filters, display the appropriate content
  const tableData = this.table.getData("active");
  if (tableData.length === 0) {
    this.existingData = false;
    return;
  }
  this.existingData = true;

  // Ensure that the properties that will be used for this chart exist before begining computation
  if (
    "ModesofTransportation" in tableData[0] === false &&
    "mode_of_transport" in tableData[0] === false
  )
    return;
  // Ensure that the properties that will be used for this chart exist before begining computation
  if ("etaToDateShipmentCleared" in tableData[0] === false) return;

  // Sort Shipment in descending order
  tableData.sort(function (shipment1, shipment2) {
    // negative number means that shipment1 is sorted before shipment2
    // positive number means that shipment2 is sorted before shipment 1
    const shipment1ArrivalDate =
      shipment1.ArrivalDate || shipment1.arrival_date;
    const shipment2ArrivalDate =
      shipment2.ArrivalDate || shipment2.arrival_date;
    return (
      new Date(shipment2ArrivalDate.replace(/-/g, "/")) -
      new Date(shipment1ArrivalDate.replace(/-/g, "/"))
    );
  });

  // Create date objects using the latest data and earliest date within the recordset
  const firstRecordArrivalDate =
    tableData[0].ArrivalDate || tableData[0].arrival_date;

  const lastRecordArrivalDate =
    tableData[tableData.length - 1].ArrivalDate ||
    tableData[tableData.length - 1].arrival_date;

  const firstDate = new Date(firstRecordArrivalDate.replace(/-/g, "/")); // change yyyy-mm-dd to yyyy/mm/dd
  const lastDate = new Date(lastRecordArrivalDate.replace(/-/g, "/"));

  let earliestDate, latestDate;
  if (firstDate < lastDate) {
    earliestDate = firstDate;
    latestDate = lastDate;
  } else {
    earliestDate = lastDate;
    latestDate = firstDate;
  }

  // If the earliest date year and latest date year is not the same then set x axis labels using years.
  // If the years are equal and the months are not then set x axis labels using months.
  // If the years are equal,the months are equal, then set x axis labels using weeks.
  if (earliestDate.getFullYear() !== latestDate.getFullYear()) {
    this.generateChart(earliestDate, latestDate, tableData, "year");
  } else if (earliestDate.getMonth() !== latestDate.getMonth()) {
    this.generateChart(earliestDate, latestDate, tableData, "month");
  } else {
    this.generateChart(earliestDate, latestDate, tableData, "week");
  }
  // Change the object property which is binding to the bar chart prop which will cause the chart to reload with the updated data
  this.chartData = structuredClone(this.chartData);
}

// Set x axis labels
function generateChart(earliestDate, latestDate, tableData, period) {
  const periodMethod = {
    year: "getFullYear",
    month: "getMonth",
    week: "getDay",
    day: "getDay",
  };

  const aggregateData = {};
  this.setXAxisLabel(
    earliestDate,
    latestDate,
    aggregateData,
    period,
    periodMethod[period]
  );

  // Generate count data for the x-axis labels
  this.generateChartData(
    tableData,
    aggregateData,
    period,
    periodMethod[period]
  );

  //  Assign the appropriate count data to the corresponding x-axis label
  this.setChartData(aggregateData);
}

function setChartData(aggregateData) {
  this.chartData.datasets[0].data = [];
  this.chartData.datasets[1].data = [];
  let count = 0;
  const insideKpiPercentageCount = [];
  for (const [key, countData] of Object.entries(aggregateData)) {
    this.chartData.datasets[0].data.push(countData.insideKpi);
    this.chartData.datasets[1].data.push(countData.outsideKpi);
    const insideKpiPercentage = Math.ceil(
      (countData.insideKpi / (countData.insideKpi + countData.outsideKpi)) * 100
    );
    insideKpiPercentageCount.push(insideKpiPercentage);
    count++;
  }
  this.chartData.datasets[2].data = insideKpiPercentageCount;
  // Create an array with the value 90 'count' number of times
  // This will be use to add the KPI percentage target to the chart.
  this.chartData.datasets[3].data = Array(count).fill(90);
}

function generateChartData(
  tableData,
  aggregateData,
  periodType,
  retreivePeriodValue
) {
  tableData.reduce((recordCount, record) => {
    const arrivalDate = record.ArrivalDate || record.arrival_date;
    const recordDate = new Date(arrivalDate.replace(/-/g, "/")); //Replace '-' with '/' eg: 2022-01-01 = 2022/01/01
    const periodCalculation =
      periodType === "week"
        ? () => calculateWeekNumber(recordDate)
        : () => recordDate[retreivePeriodValue]();
    const modeOfTranportation =
      record.ModesofTransportation || record.mode_of_transport;
    if (modeOfTranportation.toUpperCase() === "AIR") {
      if (
        record.etaToDateShipmentCleared <=
        this.selectedCountrySpecification.chart.airKPI
      ) {
        recordCount[periodCalculation()].insideKpi += 1;
        return recordCount;
      }
      recordCount[periodCalculation()].outsideKpi += 1;
      return recordCount;
    } else if (modeOfTranportation.toUpperCase() === "OCEAN") {
      if (
        record.etaToDateShipmentCleared <=
        this.selectedCountrySpecification.chart.oceanKPI
      ) {
        recordCount[periodCalculation()].insideKpi += 1;
        return recordCount;
      }
      recordCount[periodCalculation()].outsideKpi += 1;
      return recordCount;
    }
    return recordCount;
  }, aggregateData);
}

function setXAxisLabel(
  periodStart,
  periodEnd,
  aggregateData,
  periodType,
  retreivePeriodValue
) {
  if (periodType === "week") {
    const weeksWithinMonth = calculateWeeksWithinMonth(periodStart, periodEnd);
    this.initialiseCountObject(1, weeksWithinMonth, aggregateData, periodType);
    return;
  }
  if (periodType === "month") {
    this.initialiseCountObject(
      periodStart[retreivePeriodValue](),
      periodEnd[retreivePeriodValue](),
      aggregateData,
      periodType
    );
    return;
  } else {
    this.initialiseCountObject(
      periodStart[retreivePeriodValue](),
      periodEnd[retreivePeriodValue](),
      aggregateData,
      periodType
    );
  }
}

function initialiseCountObject(
  periodStart,
  periodEnd,
  aggregateData,
  periodType
) {
  for (let period = periodStart; period <= periodEnd; period++) {
    aggregateData[period] = {
      insideKpi: 0,
      outsideKpi: 0,
    };
    switch (periodType) {
      case "week":
        this.chartData.labels.push(`Week ${period}`);
        break;
      case "month":
        this.chartData.labels.push(`${this.months[period]}`);
        break;
      case "year":
        this.chartData.labels.push(`${period}`);
        break;
    }
  }
}

export function calculateWeekNumber(date) {
  return Math.ceil(date.getDate() / 7);
}

export function calculateWeeksWithinMonth(periodStart, periodEnd) {
  const startOfMonth = new Date(
    periodStart.getFullYear(),
    periodStart.getMonth(),
    1
  );
  const endOfMonth = new Date(
    periodStart.getFullYear(),
    periodStart.getMonth() + 1,
    0
  );
  const daysWithinMonth = Math.ceil(
    (endOfMonth - startOfMonth) / (1000 * 60 * 60 * 24)
  );
  return Math.ceil((daysWithinMonth + 1) / 7);
}
</script>

<style lang="scss">
.chart-label {
  color: black;
}
.no-data-text {
  color: black;
}
</style>