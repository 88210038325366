<template>
  <section class="login" v-if="!userLoggedIn">
    <div class="login-page-content">
      <div class="logo">
        <img src="../assets/logo.png" />
      </div>
      <form class="login-form" @submit.prevent="submitLoginInfo">
        <div class="form-field field">
          <label class="label" for="username">Username</label>
          <p class="control has-icons-left has-icons-right">
            <input
              class="input"
              type="text"
              placeholder="Username"
              v-model="username"
              id="username"
              required
            />
            <span class="icon is-small is-left">
              <font-awesome-icon icon="fas fa-user" />
            </span>
            <span v-if="correctUsername" class="icon is-small is-right">
              <font-awesome-icon icon="fas fa-check" class="success" />
            </span>
            <span
              v-if="correctUsername === false"
              class="icon is-small is-right"
            >
              <font-awesome-icon
                icon="fas fa-exclamation-triangle"
                class="failed"
              />
            </span>
          </p>
          <p v-if="correctUsername === false" class="help helper-message">
            This username is invalid
          </p>
        </div>
        <div class="form-field field">
          <label class="label" for="password">Password</label>
          <p class="control has-icons-left has-icons-right">
            <input
              class="input"
              type="password"
              placeholder="Password"
              v-model="password"
              required
              id="password"
            />
            <span class="icon is-small is-left">
              <font-awesome-icon icon="fa-solid fa-lock" />
            </span>
            <span v-if="correctPassword" class="icon is-small is-right">
              <font-awesome-icon icon="fas fa-check" class="success" />
            </span>
            <span
              v-if="correctPassword === false"
              class="icon is-small is-right"
            >
              <font-awesome-icon
                icon="fas fa-exclamation-triangle"
                class="failed"
              />
            </span>
          </p>
          <p v-if="correctPassword === false" class="help helper-message">
            This password is invalid
          </p>
        </div>
        <button class="button is-white" ref="loginButton">Login</button>
      </form>
    </div>

    <div class="login-page-art-container">
      <img class="login-page-art" src="../assets/login_page_art.svg" alt="" />
    </div>
  </section>
</template>

<script>
import { url } from "../App.vue";

export default {
  data() {
    return {
      username: "",
      password: "",
      correctUsername: null,
      correctPassword: null,
      userLoggedIn: true,
    };
  },
  methods: {
    submitLoginInfo,
    checkUserLogin,
  },
  created() {
    this.checkUserLogin();
  },
};

async function checkUserLogin() {
  if (getCookie("JWT") !== "") {
    const response = await fetch(`${url}/check-login`, {
      credentials: "include", // Ensure that the credentials cookie is included within cross-origin requests
      method: "GET", // POST, PUT, DELETE, etc.
    });
    // Retrieve the response body and assign the necessary boolean values to perform conditional rendering
    const responseBody = await response.json();
    if (response.ok && responseBody.jwtValid) {
      // If the JWT Token was correct, redirect to the dashboard page
      this.$router.push({
        name: "Dashboard",
      });
      return;
    }
  }
  this.userLoggedIn = false;
}

function getCookie(cookieName) {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) == " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}

async function submitLoginInfo(event) {
  // Add a loading icon to the login button when it is pressed
  this.$refs.loginButton.classList.add("is-loading");
  // Retrieve form inputs in the request body using the two way data binding properties
  const requestBody = {
    username: this.username,
    password: this.password,
  };
  // Send request to the server endpoint
  const response = await fetch(`${url}/login`, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include", // Ensure that the credentials cookie is included within cross-origin requests
    method: "POST", // POST, PUT, DELETE, etc.
    body: JSON.stringify(requestBody),
  });
  // Retrieve the response body and assign the necessary boolean values to perform conditional rendering
  const responseBody = await response.json();
  this.correctUsername = responseBody.correctUsername;
  this.correctPassword = responseBody.correctPassword;
  this.$refs.loginButton.classList.remove("is-loading");
  // If the username and password was correct, redirect to the dashboard page
  if (response.ok) {
    this.$router.push({
      name: "Dashboard",
    });
  }
}
</script>

<style>
section.login {
  display: flex;
  width: 65%;
  height: 80vh;
  margin: 0 auto;
  border-radius: 10px;
  margin-top: 4rem;
  text-align: center;
}

div.login-page-content {
  flex: 1 1 50%;
  background-color: #106eec;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}

.login-page-art {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.login-page-art-container {
  flex: 1 1 50%;
  background-color: rgba(227, 231, 255, 0.59);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

div.logo {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
div.logo img {
  display: block;
  width: 60%;
  object-fit: cover;
  margin-right: 2rem;
}

form.login-form {
  width: 80%;
  padding-bottom: 5rem;
}
form.login-form label {
  color: white;
}
form.login-form button {
  margin-top: 1.5rem;
  width: 40%;
}
form.login-form button:focus,
form.login-form input:focus {
  outline: 1px solid white;
  outline-offset: 2px;
}
.form-field {
  padding-bottom: 0.5rem;
}
.helper-message {
  background-color: #f14668;
  color: white;
}

.success {
  color: #00c4a7;
}
.failed {
  color: #f14668;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
  section.login {
    display: flex;
    width: 50vw;
    height: 70vh;
  }

  div.logo img {
    width: 50%;
  }

  form.login-form {
    width: 90%;
  }
}

@media only screen and (max-width: 992px) {
  section.login {
    height: 50vh;
  }
  div.logo img {
    width: 75%;
  }
  form.login-form {
    width: 90%;
    padding-bottom: 3rem;
  }
}

/* Media Queries */
@media only screen and (max-width: 768px) {
  section.login {
    height: 70vh;
    width: 90%;
  }

  div.login-page-content {
    border-radius: 10px;
  }

  .login-page-art-container {
    display: none;
  }

  form.login-form {
    width: 90%;
  }
}
</style>