<template>
  <section class="top-menu">
    <font-awesome-icon icon="fa-solid fa-bars" @click="toggleSideBar" class="nav-bar-toggle" />
    <h1 class="table-title">{{ tableSpecifications.title }}</h1>
    <div class="filter-input-container">
      <select id="filter-field" ref="filterField" @change="setFilterInputType" :disabled="isSummaryPage">
        <option selected value="">All</option>
        <option :selected="isSummaryPage" value="date" :hidden="!isSummaryPage">
          Date
        </option>
        <option v-for="column of selectedCountrySpecification.tableDefinition" :key="column.field" :value="column.field"
          :hidden="isSummaryPage">
          {{ column.title }}
        </option>
      </select>

      <select id="filter-type" ref="filterType" :disabled="isSummaryPage">
        <option selected value="like">like</option>
        <option value="=">=</option>
        <option value="<">&lt;</option>
        <option value="<=">&lt;=</option>
        <option value=">">&gt;</option>
        <option value=">=">&gt;=</option>
        <option value="!=">!=</option>
        <option value="within" :hidden="!isSummaryPage" :selected="filerInputType === 'datetime' || isSummaryPage">
          within
        </option>
      </select>

      <input id="filter-value" ref="filterValue" type="text" placeholder="value to filter" maxlength="20"
        v-if="filerInputType != 'datetime' && !isSummaryPage" @input="clearSearch(false)" />
      <div class="date-filter-values" v-else>
        <input id="filter-value-date-start" ref="filterValueDateStart" type="date" placeholder="value to filter" />
        -
        <input id="filter-value-date-end" ref="filterValueDateEnd" type="date" placeholder="value to filter" />
      </div>
      <button id="filter-apply" @click="applyFilter" :hidden="!isSummaryPage && searchState == 'searched'">Search</button>
      <button class="button--danger" @click="clearSearch(true)"
        :hidden="isSummaryPage || searchState != 'searched'">Clear Search</button>
    </div>
    <div class="toggle-data-view-container" v-if="'chart' in this.selectedCountrySpecification">
      <button v-show="dataView[0] === 'table'" @click="changeDataView('chart')" class="toggle-data-view-button">
        View Chart
      </button>
      <button v-show="dataView[0] === 'chart'" @click="changeDataView('table')" class="toggle-data-view-button">
        View Table
      </button>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    tableSpecifications: {
      default() {
        return { title: "Summary Page" };
      },
    },
    selectedCountrySpecification: {
      default() {
        return {};
      },
    },
    groupedFilters: {
      default() {
        return [];
      },
    },
    table: {
      default() {
        return {};
      },
    },
    dataView: {
      default() {
        return {};
      },
    },
    isSummaryPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filerInputType: "",
      searchState: "default"
    };
  },
  methods: {
    applyFilter,
    clearFilterInputs,
    setFilterInputType,
    toggleSideBar,
    changeDataView,
    setFilters,
    setSummaryPageDefaultFilter,
    getNewFilter,
    search,
    clearSearch
  },
  mounted() {
    this.setSummaryPageDefaultFilter();
  },
};

function setSummaryPageDefaultFilter() {
  if (!this.isSummaryPage) return;
  const [startDateOfPreviousMonth, endDateOfPreviousMonth] =
    generatePreviousMonthStartEndDates();
  this.$refs.filterValueDateStart.value = `${startDateOfPreviousMonth.toISOString().split("T")[0]
    }`;
  this.$refs.filterValueDateEnd.value = `${endDateOfPreviousMonth.toISOString().split("T")[0]
    }`;
}

export function generatePreviousMonthStartEndDates() {
  // This function generates a date filter limiting the records to the previous month
  const currentDate = new Date();
  let previousMonth = currentDate.getMonth() - 1;
  previousMonth = currentDate.getMonth() === 0 ? 11 : previousMonth;
  // If the current date's month is January, then the previous month's year will be the previous year.
  const previousMonthYear =
    currentDate.getMonth() === 0
      ? currentDate.getFullYear() - 1
      : currentDate.getFullYear();
  const startDateOfPreviousMonth = new Date(
    previousMonthYear,
    previousMonth,
    1
  );
  const endDateOfPreviousMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );
  return [startDateOfPreviousMonth, endDateOfPreviousMonth];
}

function changeDataView(view) {
  this.dataView[0] = view;
}

function toggleSideBar() {
  this.$emit("toggleIconClicked");
}
function clearSearch(reset = false) {
  if (this.searchState == 'searched') {
    this.table.clearFilter();
    this.searchState = 'default'
    if (reset) {
      this.$refs.filterValue.value = ''
    }
  }
}
function applyFilter() {
  const filterFieldValue =
    this.$refs.filterField.options[this.$refs.filterField.selectedIndex].value;

  //  If a field is not selected or there is no value entered for the criteria do nothing
  // In future it should guide the user to enter the appropriate data
  if (filterFieldValue === "") {
    this.search()
    return;
  }

  const filterFieldLabel =
    this.$refs.filterField.options[this.$refs.filterField.selectedIndex]
      .innerText;

  const filterTypeValue =
    this.$refs.filterType.options[this.$refs.filterType.selectedIndex].value;

  let newFilter = this.getNewFilter(filterFieldValue, filterTypeValue, filterFieldLabel);

  if (filterTypeValue === "within") {
    // If the date is not set do nott apply the filter
    if (this.$refs.filterValueDateStart.value === "") {
      return;
    }
    // If this is the summary page, add filter to be manually applied.
    if (this.isSummaryPage) {
      this.$emit("filtersChanged", newFilter);
      return;
    }
    this.clearFilterInputs(true);
    this.setFilterInputType();
  } else {
    if (this.$refs.filterValue.value === "") {
      return;
    }
  }


  if (filterTypeValue !== "within") {
    this.clearFilterInputs();
  }

  this.setFilters(newFilter);
}

function getNewFilter(filterFieldValue, filterTypeValue, filterFieldLabel) {
  let newFilter = [];

  if (filterTypeValue === "within") {
    // If the date is not set do nott apply the filter
    if (this.$refs.filterValueDateEnd.value === "") {
      this.$refs.filterValueDateEnd.value =
        this.$refs.filterValueDateStart.value;
    }
    newFilter = [
      {
        field: filterFieldValue,
        type: ">=",
        value: this.$refs.filterValueDateStart.value,
        filterType: "date",
        applied: true,
        fieldTitle: filterFieldLabel,
      },
      {
        field: filterFieldValue,
        type: "<=",
        value: this.$refs.filterValueDateEnd.value,
        filterType: "date",
      },
    ];
    // If this is the summary page, add filter to be manually applied.
  } else {
    newFilter = [
      {
        field: filterFieldValue,
        type: filterTypeValue,
        value: this.$refs.filterValue.value.toUpperCase(),
        applied: true,
        fieldTitle: filterFieldLabel,
      },
    ];
  }
  return newFilter
}

function search() {
  const filterTypeValue = this.$refs.filterType.options[this.$refs.filterType.selectedIndex].value;
  const filters = this.selectedCountrySpecification.tableDefinition.map(column => {
    const filterFieldValue = column.field
    const newFilter = this.getNewFilter(filterFieldValue, filterTypeValue, '')
    const [destructuredFilter] = newFilter
    return destructuredFilter;
  });
  this.table.setFilter([filters]);
  this.searchState = 'searched'
}

function setFilters(newFilter) {
  const filterExist = this.groupedFilters.some(
    (filter) => JSON.stringify(filter) === JSON.stringify(newFilter)
  );
  if (filterExist) return;
  this.groupedFilters.push(newFilter);
  this.table.addFilter(newFilter);
  this.$emit("filtersChanged");
}

function clearFilterInputs(dateInput = false) {
  this.$refs.filterField.selectedIndex = 0;
  this.$refs.filterType.selectedIndex = 0;
  if (dateInput) {
    this.$refs.filterValueDateEnd.value = "";
    this.$refs.filterValueDateStart.value = "";
    return;
  }
  this.$refs.filterValue.value = "";
}

function setFilterInputType() {
  const selectedField =
    this.$refs.filterField.options[this.$refs.filterField.selectedIndex].value;

  const selectedColumn = this.selectedCountrySpecification.tableDefinition.find(
    (column) => {
      if (column.field === selectedField) return column;
    }
  );

  if (!selectedColumn) {
    this.filerInputType = "";
    return;
  }
  if (
    "formatter" in selectedColumn &&
    selectedColumn.formatter === "datetime"
  ) {
    this.filerInputType = "datetime";
    return;
  }
  this.filerInputType = "";
}
</script>

<style lang="scss">
section.dashboard-content-area {
  .top-menu {
    background-color: white;
    grid-row: 1;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    padding: 1rem calc(6% / 2);
    box-shadow: 0px 0px 10px 1px rgb(208, 203, 203);
    justify-content: space-between;

    .table-title {
      font-size: 1.5rem;
      color: #3ddc97;
      font-weight: bold;
    }

    .nav-bar-toggle {
      color: rgb(213, 213, 213);
      font-size: 2rem;
      cursor: pointer;
    }
  }
}

.filter-input-container {

  select,
  input {
    font-family: "Montserrat", sans-serif;
    width: 14rem;
    height: 2.2rem;
    background: none;
    border: 1px solid #106eec;
    border-radius: 10px;
    margin: 0 0.2rem;
    padding: 0 1rem;
  }

  option {
    font-family: "Montserrat", sans-serif;
    background: none;
    padding: 2rem;
  }
}

.filter-input-container,
.toggle-data-view-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;

  button {
    &.button--danger {
      /* Apply the danger modifier class to the button */
      background: red;

      &:hover {
        border: 1px solid red;
        background: white;
        color: red;
      }
    }

    background: #3ddc97;
    width: 7rem;
    height: 2.2rem;
    border-radius: 10px;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 0.2rem;
    font-weight: bold;

    &:hover {
      border: 1px solid #3ddc97;
      background: white;
      color: #3ddc97;
    }

    &:active {
      border: none;
      background: none;
      color: #106eec;
    }
  }

  #filter-field {
    width: 12rem;
  }

  #filter-type {
    width: 6.1rem;
  }

  #filter-value {
    width: 12rem;
    text-transform: uppercase;
  }
}

#filter-value-date-start,
#filter-value-date-end {
  width: 11rem;
}

.date-filter-values {
  font-size: 1rem;
  color: black;
}</style>