<template>
  <section class="table-subheader">
    <div class="user-filter-container">
      <div v-for="[index, filterGroup] of this.groupedFilters
        .filter((filter) => filter[0].setBy != 'domain')
        .entries()" :key="index" class="filter">
        <div v-if="filterGroup.length >= 2" class="active-filters" :class="{ 'not-applied': !filterGroup[0].applied }">
          <button class="remove-filter" @click="removeFilter(filterGroup)" v-if="filterGroup[0].setBy != 'predefined'">
            <font-awesome-icon icon="fa-regular fa-circle-xmark" />
          </button>
          <div class="filter-content" @click="activateFilter(filterGroup)" :class="{
        'unremovable-filter': filterGroup[0].setBy === 'predefined',
      }" v-if="filterGroup[0].label">
            {{ filterGroup[0].label }}
          </div>
          <div class="filter-content" @click="activateFilter(filterGroup)" :class="{
        'unremovable-filter': filterGroup[0].setBy === 'predefined',
      }" v-else-if="filterGroup[0].filterType === 'date'">
            {{ filterGroup[0].fieldTitle }} within {{ filterGroup[0].value }} to
            {{ filterGroup[1].value }}
          </div>
        </div>
        <div v-else class="active-filters" :class="{ 'not-applied': !filterGroup[0].applied }">
          <button class="remove-filter" @click="removeFilter(filterGroup)" v-if="filterGroup[0].setBy != 'predefined'">
            <font-awesome-icon icon="fa-regular fa-circle-xmark" />
          </button>
          <div class="filter-content" @click="activateFilter(filterGroup)" :class="{
        'unremovable-filter': filterGroup[0].setBy === 'predefined',
      }" v-if="filterGroup[0].label">
            {{ filterGroup[0].label }}
          </div>
          <div class="filter-content" @click="activateFilter(filterGroup)" :class="{
        'unremovable-filter': filterGroup[0].setBy === 'predefined',
      }" v-else>
            {{ filterGroup[0].fieldTitle }} {{ filterGroup[0].type }}
            {{ filterGroup[0].value }}
          </div>
        </div>
      </div>
    </div>
    <div class="field-selection dropdown" :class="{ 'is-active': displayFieldSelection }">
      <!-- .self means that if the dropdown menu was selected instead of the dropdown selection button item itself
           do not toggle drop down component -->
      <div class="dropdown-trigger">
        <button class="button" ref="fieldSelectionButton" aria-haspopup="true" aria-controls="dropdown-menu"
          @click.self="toggleTableFieldSelection()">
          <span class="selection-button-label">Options</span>
          <span class="icon is-small is-right selection-button-icon">
            <font-awesome-icon icon="fas fa-angle-down" aria-hidden="true"></font-awesome-icon>
          </span>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <div class="dropdown-item dropdown-export-button-container">
                <button class="export-to-spreadsheet" @click="exportToSpreadsheet">
                  Export To Spreadsheet
                </button>
              </div>
              <hr class="dropdown-divider" />
              <div class="tabs is-centered">
                <ul>
                  <li :class="{ 'is-active': showTableFieldOptions }" @click="showTableFieldOptions = true">
                    <a>Fields</a>
                  </li>
                  <li @click="showTableFieldOptions = false" :class="{ 'is-active': !showTableFieldOptions }">
                    <a>Country</a>
                  </li>
                </ul>
              </div>
              <div v-if="showTableFieldOptions">
                <div class="dropdown-item" v-for="column of selectedCountrySpecification.tableDefinition.filter(
        (column) => column.selectable
      )" :key="column.field">
                  <input type="checkbox" name="primaryList" ref="primaryList" @click="displayField(column, $event)"
                    :checked="column.visible" />
                  <label>{{ column.title }}</label>
                </div>
              </div>
              <div v-else>
                <div class="dropdown-item" v-for="country of tableSpecifications.countries" :key="country.name">
                  <input type="checkbox" name="primaryList" ref="primaryList" @click="selectCountry(country)"
                    :checked="country.active" />
                  <label>{{ country.name }}</label>
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import { read, utils, writeFile } from "xlsx";
export default {
  data() {
    return {
      showTableFieldOptions: true,
      displayFieldSelection: false,
    };
  },
  props: [
    "groupedFilters",
    "tableSpecifications",
    "selectedCountrySpecification",
    "table",
  ],
  methods: {
    selectCountry,
    displayField,
    activateFilter,
    removeFilter,
    toggleTableFieldSelection,
    exportToSpreadsheet,
  },
};

function selectCountry(selectedCountry) {
  for (const country of this.tableSpecifications.countries) {
    if (_.isEqual(selectedCountry, country) && country.active) {
      continue;
    } else if (_.isEqual(selectedCountry, country)) {
      country.active = !country.active;
      this.$emit("countryChanged");
    } else {
      country.active = false;
    }
  }
}

function displayField(column) {
  if (column.visible) {
    this.table.hideColumn(column.field);
    column.visible = false;
  } else {
    this.table.showColumn(column.field);
    column.visible = true;
  }
  this.table.redraw(true);
  this.$emit("columnsChanged", this.selectedCountrySpecification);
}
function activateFilter(filterGroup) {
  // If the filter is not applied, then apply it to the table
  if (!filterGroup[0].applied) {
    // If the filter group contains more than one member and it isn't a date filter then
    // add the filter with an array wrapper to enforce the OR operation on filters contained within the filter group.
    // [[{}, {}]] === {} || {}
    // [{}, {}] === {} && {}
    if (filterGroup[0].filterType === "or") {
      this.table.addFilter([filterGroup]);
    } else {
      this.table.addFilter(filterGroup);
    }
  } else {
    // Remove the filter if it is already applied
    if (filterGroup[0].filterType === "or") {
      this.table.removeFilter([filterGroup]);
    } else {
      this.table.removeFilter(filterGroup);
    }
  }
  // Change the applied property for UI state update.
  filterGroup[0].applied = !filterGroup[0].applied;
  // Ensure that on filter activation that the table is properly rendered with the new dataset
  // If this is not done and the previous filter returned an empty set and the newly applied filter
  // is not empty, then the records will be correctly filtered but not correctly displayed since the
  // previous height of the table was 0.
  this.table.redraw();
  this.$emit("filtersChanged");
}

function removeFilter(filterToBeRemoved) {
  if (filterToBeRemoved.filterType === "or") {
    this.table.removeFilter([filterToBeRemoved]);
  } else {
    this.table.removeFilter(filterToBeRemoved);
  }
  const elementIndexToBeRemoved =
    this.groupedFilters.indexOf(filterToBeRemoved);
  this.groupedFilters.splice(elementIndexToBeRemoved, 1);
  this.$emit("filtersChanged");
}
function toggleTableFieldSelection() {
  this.displayFieldSelection = !this.displayFieldSelection;
}

function exportToSpreadsheet() {
  //  CREATE NEW EXCEL "FILE"
  // Create a new workbook
  const workbook = utils.book_new();
  // Get table filtered data.
  const records = this.table.getData("active");
  // Traverse each record and update the property to user the user friendly field definition instead of the
  // properties technical name
  for (const record of records) {
    // The table definition contains the user friendly name for the fields.
    this.selectedCountrySpecification.tableDefinition.forEach((property) => {
      // In this case, the user friendly name is stored in property.title
      // and the technical name is stored in property.field
      // retrieve the value from the record and set it to the new property using the user friendly name
      // and delete the old property from the record using the technical name
      if (
        property.title.includes("Attachments") ||
        property.title.includes("Additional Info")
      ) {
        return;
      }
      record[property.title] = record[property.field];
      // If the names are the same, then do not delete because the property would have already been overwritten
      if (property.title !== property.field) {
        // If the field isn't visible on the dashboard do not print to spreadsheet
        delete record[property.field];
      }
      if (!property.visible) {
        delete record[property.field];
        delete record[property.title];
      }
    });
  }
  // Convert Array of JSON Objects to worksheet
  const worksheet = utils.json_to_sheet(records);
  workbook.SheetNames.push("Sheet1");
  workbook.Sheets["Sheet1"] = worksheet;
  const currentDateLabel = new Date().toISOString().split("T")[0];
  writeFile(
    workbook,
    `Ramps Logistics ${this.tableSpecifications.title} (${currentDateLabel}).xlsx`
  );
}
</script>

<style lang="scss">
.dropdown-content {
  max-height: 50vh;
  overflow: scroll;

  .dropdown-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 2rem;

    h3 {
      padding-bottom: 0.5rem;
    }

    input {
      width: 1rem;
      height: 1rem;
      margin-right: 1rem;
    }
  }
}

.dropdown-trigger {
  width: 100%;

  .button {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 5fr 1fr;
    width: 100%;
    border: 1px solid #106eec;

    &:hover {
      box-shadow: 0px 0px 5px 0px #bcbcbc;
    }

    svg {
      width: 1rem;
    }
  }
}

.dropdown-menu {
  width: 100%;
  right: 0;
  left: auto;
}

.dropdown {
  justify-content: flex-end;
}

.table-subheader {
  grid-row: 2;
  display: grid;
  grid-template-columns: 12fr 3.5fr;
  color: black;
  padding: 1rem 3% 0rem 3%;
  background-color: rgba(247, 244, 244, 0.342);
}

.active-filters {
  height: 100%;
  display: flex;

  .filter-content {
    background-color: #3ddc97;
    color: white;
    padding: 0.4rem 1rem 0.4rem 0.5rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  button.remove-filter {
    background-color: #3ddc97;
    border: none;
    color: white;
    display: flex;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: white;
      color: #3ddc97;
    }
  }
}

.dropdown-content .dropdown-export-button-container {
  height: fit-content;

  button.export-to-spreadsheet {
    background-color: #3ddc97;
    border: none;
    color: white;
    font-size: 1rem;
    border-radius: 10px;
    padding: 0.5rem;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    cursor: pointer;

    &:hover {
      background-color: white;
      color: #3ddc97;
    }
  }
}

.user-filter-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 1rem;
  padding-right: 1rem;

  div.filter {
    flex-basis: max-content;
  }
}

.user-filter-container {
  .not-applied {

    button.remove-filter,
    div.filter-content {
      // text-decoration: line-through;
      text-decoration-color: white;
      background-color: #3ddc9770;
    }
  }
}

.active-filters .unremovable-filter {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.selection-button-label,
.selection-button-icon {
  pointer-events: none;
}
</style>