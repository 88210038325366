<template>
  <nav>
    <div class="nav-content nav-customer-logo-section">
      <div class="customer-logo-container">
        <img :src="customerLogo" alt="" />
      </div>
      <h1>{{ customerName }}</h1>
    </div>
    <aside class="sidebar-link-container">
      <div v-for="[index, menuItem] of menuItems.entries()" v-bind:key="index">
        <div
          class="group-label-container nav-content"
          @click="handleMenuItemOnClick(menuItem, $event)"
          :class="[
            { active: menuItem.displayChildItems },
            { 'action-item': menuItem.childItems.length == 0 },
          ]"
        >
          <font-awesome-icon :icon="menuItem.icon" />
          <p>{{ menuItem.name }}</p>
          <font-awesome-icon
            v-show="menuItem.childItems.length != 0"
            icon="fa-solid fa-angle-down"
            class="drop-down-icon"
          />
        </div>
        <div
          class="child-menu-items"
          :class="{ active: menuItem.displayChildItems }"
        >
          <div
            v-for="[key, childMenuItem] of menuItem.childItems.entries()"
            v-bind:key="key"
          >
            <div
              class="drop-down-group-container nav-content"
              @click="toggleChildMenuItem(menuItem, menuItem.childItems, key)"
              :class="{ active: childMenuItem.active }"
            >
              <font-awesome-icon
                v-if="!childMenuItem.active"
                icon="fa-regular fa-circle"
              />
              <font-awesome-icon v-else icon="fa-solid fa-circle" />
              <p class="">{{ childMenuItem.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </aside>
  </nav>
</template>

<script>
import { url } from "../App.vue";
export default {
  props: ["menuItemsAndHomeConfig"],
  data() {
    return {
      customerName: "",
      customerLogo: "",
      menuItems: [],
    };
  },
  methods: {
    toggleChildMenuItem,
    toggleParentMenuItem,
    handleMenuItemOnClick,
    logout,
    goToSummaryPage,
    selectSummaryPage,
    getImgUrl,
  },
  watch: {
    menuItemsAndHomeConfig() {
      // Change indexes in order to set default opening page.
      // first index references parent menu item
      // Second index references child menu item.

      // Get a copy of the menuItems and the home config and remove the home config for the sidebar operations.
      this.menuItems = [...this.menuItemsAndHomeConfig];
      this.menuItems.shift();
      this.getImgUrl();
      this.customerName = this.menuItemsAndHomeConfig[0].customerName;
      if (this.menuItemsAndHomeConfig[1].name === "Summary") {
        this.$emit("menuItemSelected", this.menuItemsAndHomeConfig[1], true);
      } else {
        this.$emit("menuItemSelected", this.menuItemsAndHomeConfig[1].childItems[0], false);
      }      
    },
  },
};

function getImgUrl() {
  // Webpack uses require parameter to generate a context module which will map our runtime value to the appropriate asset ID
  // This is necessary because the asset's name is updated to contain the hash of the file content which we would not know at compile time.
  this.customerLogo = require("../assets/" +
    this.menuItemsAndHomeConfig[0].customerLogo);
}

async function logout() {
  const response = await fetch(`${url}/logout`, {
    credentials: "include",
    method: "GET",
  });
  if (!response.ok) return;
  this.$router.push({ name: "Login" });
}

function goToSummaryPage() {
  this.selectSummaryPage();
  this.$emit("menuItemSelected", null, true);
}

function selectSummaryPage() {
  for (const parentMenu of this.menuItems) {
    if (parentMenu.name === "Summary") {
      // Set background color to show that the menu item was selected
      parentMenu.displayChildItems = true;
      continue;
    }
    // Set other parent menu item to unselected
    parentMenu.displayChildItems = false;
    // Deselect all child elements for each parent element
    for (const menuItem of parentMenu.childItems) {
      menuItem.active = false;
    }
  }
}

// If the parent menu item isn't associated with a standard data view, invoke its associated event handler instead.
function handleMenuItemOnClick(parentMenuItem, event) {
  if (parentMenuItem.childItems.length === 0) {
    this[parentMenuItem.eventHandler]();
    // Collapse all parent menu item dropdowns
    this.toggleParentMenuItem(parentMenuItem, null, true);
    return;
  }

  this.toggleParentMenuItem(parentMenuItem, event);
}

// Loop through child menu items within given parent menu item object, reset all child menu items except the chosen one
// Then toggle chosen child item
function toggleChildMenuItem(
  parentMenuItem,
  childMenuItems,
  selectedItemIndex
) {
  // Loops through each menu item and set its submenu item to not active/displayed/selected with the false value
  for (const [index, parentMenu] of this.menuItems.entries()) {
    // If parent menu item === to current element within loop, skip reset operation so that the toggle isn't rendered useless.
    if (parentMenu.name == parentMenuItem.name) continue;
    for (const childMenu of parentMenu.childItems) {
      childMenu.active = false;
    }
  }
  for (const [index, menuItem] of childMenuItems.entries()) {
    if (index === selectedItemIndex) {
      menuItem.active = true;
      this.$emit("menuItemSelected", menuItem, false);
    } else {
      menuItem.active = false;
    }
  }
}
// Reset all parent and child menu items except the chosen child menu item within the specified parent menu item and then toggle the
// Chosen menu item.
function toggleParentMenuItem(parentMenuItem, event, summaryPage = false) {
  // Retrieve all group label containers (Parent Menu Items)
  const groupLabelContainers = document.querySelectorAll(
    ".group-label-container"
  );

  // Loops through each menu item and set its submenu item to not active/displayed/selected with the false value
  for (const [index, parentMenu] of this.menuItems.entries()) {
    // If parent menu item === to current element within loop, skip reset operation so that the toggle isn't rendered useless.
    if (parentMenu.name == parentMenuItem.name) continue;
    parentMenu.displayChildItems = false;
    // Set height of child menu item containers to 0. This was due to the fact that we had to pass a concrete compute value for our
    // animations to work and inline styles (styles applied within js) will take priority over all css rules
    groupLabelContainers[index].nextSibling.style.height = 0;
  }
  // If the menu was made active, apply the appropriate class by changing the corresponding property of the object
  // Also set the computed height of the children menut items.
  // This calculation is = height per each child menu item * the number of child menu items for the parent menu item
  if (summaryPage) return;
  parentMenuItem.displayChildItems = !parentMenuItem.displayChildItems;
  if (parentMenuItem.displayChildItems) {
    const computedHeight = parentMenuItem.childItems.length * 3.4;
    event.target.nextSibling.style.height = `${computedHeight}rem`;
    return;
  }
  // If the parent menu item was selected while it was already activated, close child menu items container
  event.target.nextSibling.style.height = 0;
}
</script>

<style lang="scss">
.nav-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.customer-logo-container {
  width: 4rem;
}
.nav-customer-logo-section h1 {
  margin-left: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
}
.customer-logo-container img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
}
.nav-customer-logo-section {
  justify-content: center;
  padding: 1rem 1rem 3rem 1rem;
}
.group-label-container,
.drop-down-group-container {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
}
.drop-down-group-container {
  padding-left: 2rem;
}
.group-label-container:hover,
.drop-down-group-container:hover {
  background-color: rgba(49, 49, 49, 0.205);
}
.drop-down-group-container.active {
  background-color: rgba(62, 51, 51, 0.205);
}

.group-label-container p,
.drop-down-group-container p {
  flex-basis: 11rem;
  flex-grow: 1;
  margin-left: 1.5rem;
  pointer-events: none;
  font-size: .8rem;
  font-weight: bold;
}

.group-label-container svg:nth-child(1),
.drop-down-group-container svg:nth-child(1) {
  font-size: 1.3rem;
  flex-basis: 1.7rem;
}

.group-label-container font-awesome-icon svg,
.group-label-container font-awesome-icon path,
.group-label-container font-awesome-icon fill {
  pointer-events: none;
}
.group-label-container .drop-down-icon {
  transform: rotateZ(90deg);
  transition: transform 0.5s ease;
  pointer-events: none;
  margin-right: 1rem;
}
.group-label-container.active .drop-down-icon {
  transform: rotateZ(0deg);
}

.group-label-container.active {
  background-color: rgba(49, 49, 49, 0.434);
}
.child-menu-items {
  height: 0px;
  overflow: hidden;
  transition: height 0.5s ease;
}
.child-menu-items.active {
  height: 3.2rem;
}
.action-item {
  padding-left: 1.1rem;
  p {
    margin-left: 1.4rem;
  }
}
</style>